import _ from 'lodash'
import { graphql } from 'gatsby'
import { Link } from 'gatsby'
import React from 'react'
import get from 'lodash/get'

import PostIndex from 'templates/PostIndex'
import Meta from 'components/Meta'
import Layout from 'components/Layout'
import SideProfile from 'components/SideProfile'
import LatestPosts from 'components/LatestPosts'

class BlogIndex extends React.Component {
  render() {
    const { data, location, pageContext } = this.props
    const { group, index } = pageContext

    const posts = get(data, 'remark.posts')
    const posts_per_page = get(data, 'site.meta.posts_per_page')

    const pageCount = Math.ceil(posts.length / posts_per_page)

    const this_page_posts_path = []
    _.forEach(group, function(o) {
      this_page_posts_path.push(o.node.remark.frontmatter.path)
    })

    const this_page_posts = []

    if (this_page_posts_path.length === 0) {
      for (var i = 0; i < posts_per_page; i++) {
        if (i < posts.length) {
          this_page_posts.push(posts[i])
        }
      }
    } else {
      _.forEach(posts, function(o) {
        if (this_page_posts_path.indexOf(o.post.frontmatter.path) > -1) {
          this_page_posts.push(o)
        }
      })
    }

    const previousUrl = index - 1 === 0 ? '' : (index - 1).toString()
    const nextUrl = (index + 1).toString()

    return (
      <Layout location={location}>
        <Meta site={get(data, 'site.meta')} />
        <div className="kotha-default-content">
          <div className="container">
            <div className="row">
              <div className="col-sm-8">
                {this_page_posts.map(({ post }, i) => (
                  <PostIndex
                    data={post}
                    options={{
                      isIndex: true,
                    }}
                    key={i}
                  />
                ))}

                <div className="post-pagination  clearfix">
                  <ul className="pagination text-left">
                    <li className="active">
                      <a>1</a>
                    </li>

                    {pageCount > 1 ? (
                      <li>
                        <Link to="/2">2</Link>
                      </li>
                    ) : (
                      ''
                    )}

                    <li>
                      <span>...</span>
                    </li>

                    <li>
                      <Link to={'/' + pageCount}>{pageCount}</Link>
                    </li>
                    <li>
                      <Link to="/2">
                        <i className="fa fa-angle-double-right" />
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-sm-4">
                <div className="kotha-sidebar">
                  <SideProfile />

                  <LatestPosts />
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query IndexQuery {
    site {
      meta: siteMetadata {
        title
        description
        url: siteUrl
        author
        twitter_url
        adsense
        posts_per_page
      }
    }
    remark: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      posts: edges {
        post: node {
          html
          frontmatter {
            layout
            title
            path
            category
            tags
            description
            date(formatString: "MMMM Do, YYYY")
            image {
              childImageSharp {
                fluid(maxWidth: 750) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
          }
        }
      }
    }
  }
`
